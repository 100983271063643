import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API, graphqlOperation } from 'aws-amplify';

@Injectable()
export class VideosService {

  companies$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  constructor() {
    this.setVideos();
  }

  async setVideos() {
    const response = await API.graphql(graphqlOperation(listCompanys));
    const tmp = response['data'].listCompanys.items;
    this.companies$.next(tmp);
  }

}

const listCompanys = `query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        shops(limit: 50000) {
          items {
            id
        		name
        		baseUrl
        		companyID
        		deactivated
            employees(limit: 50000) {
              items {
                id
                cognitoUsername
                firstName
                lastName
                jobTitle
                deactivated
                email
                number
                expoPushToken
                avatar {
                  bucket
                  region
                  key
                  mimeType
                }
                shopID
                owner
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                videos(limit: 50000) {
                  items {
                  	id
      							uuid
      							minutes
      							seconds
      							uploadedFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							convertedFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							thumpnailFile {
      							  bucket
      							  region
      							  key
      							  mimeType
      							}
      							created
      							state
      							owner
      							userID
      							_version
      							_deleted
      							_lastChangedAt
      							createdAt
      							updatedAt
                    shares(limit: 50000) {
                      items {
      								  id
      								  videoID
      								  videoShortKey
      								  link
      								  sagsnummer
      								  adresse
      								  navn
      								  phoneNumber
      								  owner
      								  disabled
      								  _version
      								  _deleted
      								  _lastChangedAt
      								  createdAt
      								  updatedAt
                        views(limit: 50000) {
                                items {
      														  id
      														  videoShareID
      														  viewDateTime
      														  videoStarted
      														  percentageViewed
      														  owner
      														  _version
      														  _deleted
      														  _lastChangedAt
      														  createdAt
      														  updatedAt
      														}
      														nextToken
      														startedAt
                        }
      								}
      								nextToken
      								startedAt
                    }
                  }
                  nextToken
                  startedAt
                }
              }
              nextToken
              startedAt
            }
          }
          nextToken
      		startedAt
        }
      }
      nextToken
      startedAt
    }
  }`

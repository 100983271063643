/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shops {
        items {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const companyByName = /* GraphQL */ `
  query CompanyByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CompanyByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      subname
      cvrNumber
      senderId
      logo {
        bucket
        region
        key
        mimeType
      }
      baseUrl
      privacyPolicyUrl
      mailTemplate {
        locale
        html
      }
      deactivated
      logoBackgroundHexColor
      headerBackgroundColor
      headerTextColor
      footerBackgroundColor
      footerTextColor
      latitude
      longitude
      googleMaps {
        center {
          latitude
          longitude
        }
        zoom
        markers {
          latitude
          longitude
        }
      }
      segment
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      employees {
        items {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShops = /* GraphQL */ `
  query SyncShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUsername
      firstName
      lastName
      jobTitle
      email
      number
      expoPushToken
      avatar {
        bucket
        region
        key
        mimeType
      }
      monthlyGoal
      locale
      deactivated
      shopID
      owner
      statisticsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      statistics {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      videos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userbyCognitoUsername = /* GraphQL */ `
  query UserbyCognitoUsername(
    $cognitoUsername: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userbyCognitoUsername(
      cognitoUsername: $cognitoUsername
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserStatistics = /* GraphQL */ `
  query GetUserStatistics($id: ID!) {
    getUserStatistics(id: $id) {
      id
      owner
      lastVideoShared
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listUserStatisticss = /* GraphQL */ `
  query ListUserStatisticss(
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStatisticss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const userStatisticsByUser = /* GraphQL */ `
  query UserStatisticsByUser(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatisticsByUser(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserStatistics = /* GraphQL */ `
  query SyncUserStatistics(
    $filter: ModelUserStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        lastVideoShared
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      uuid
      minutes
      seconds
      durationInMillis
      draftID
      uploadedFile {
        bucket
        region
        key
        mimeType
      }
      convertedFile {
        bucket
        region
        key
        mimeType
      }
      thumpnailFile {
        bucket
        region
        key
        mimeType
      }
      created
      pinned
      state
      owner
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      shares {
        items {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        minutes
        seconds
        durationInMillis
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        uuid
        minutes
        seconds
        durationInMillis
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoDraft = /* GraphQL */ `
  query GetVideoDraft($id: ID!) {
    getVideoDraft(id: $id) {
      id
      durationInMillis
      name
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      pinned
      addIntro
      addOverlayLogo
      owner
      copyOfDraftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      createdVideos {
        items {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      copyOf {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      clips {
        items {
          id
          title
          index
          durationInMillis
          pinned
          owner
          draftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVideoDrafts = /* GraphQL */ `
  query ListVideoDrafts(
    $filter: ModelVideoDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoDrafts = /* GraphQL */ `
  query SyncVideoDrafts(
    $filter: ModelVideoDraftFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoDrafts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoClip = /* GraphQL */ `
  query GetVideoClip($id: ID!) {
    getVideoClip(id: $id) {
      id
      title
      index
      durationInMillis
      clip {
        bucket
        region
        key
        mimeType
      }
      thumpnail {
        bucket
        region
        key
        mimeType
      }
      pinned
      owner
      draftID
      userID
      shopID
      companyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shops {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        subname
        cvrNumber
        senderId
        logo {
          bucket
          region
          key
          mimeType
        }
        baseUrl
        privacyPolicyUrl
        mailTemplate {
          locale
          html
        }
        deactivated
        logoBackgroundHexColor
        headerBackgroundColor
        headerTextColor
        footerBackgroundColor
        footerTextColor
        latitude
        longitude
        googleMaps {
          zoom
        }
        segment
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        employees {
          nextToken
          startedAt
        }
      }
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      draft {
        id
        durationInMillis
        name
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        addIntro
        addOverlayLogo
        owner
        copyOfDraftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdVideos {
          nextToken
          startedAt
        }
        copyOf {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        clips {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listVideoClips = /* GraphQL */ `
  query ListVideoClips(
    $filter: ModelVideoClipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoClips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        index
        durationInMillis
        clip {
          bucket
          region
          key
          mimeType
        }
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        owner
        draftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoClips = /* GraphQL */ `
  query SyncVideoClips(
    $filter: ModelVideoClipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoClips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        index
        durationInMillis
        clip {
          bucket
          region
          key
          mimeType
        }
        thumpnail {
          bucket
          region
          key
          mimeType
        }
        pinned
        owner
        draftID
        userID
        shopID
        companyID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoShare = /* GraphQL */ `
  query GetVideoShare($id: ID!) {
    getVideoShare(id: $id) {
      id
      videoID
      videoShortKey
      link
      sagsnummer
      adresse
      externalLink
      navn
      disabled
      skipDisabling
      phoneNumber
      message
      owner
      state
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      video {
        id
        uuid
        minutes
        seconds
        durationInMillis
        draftID
        uploadedFile {
          bucket
          region
          key
          mimeType
        }
        convertedFile {
          bucket
          region
          key
          mimeType
        }
        thumpnailFile {
          bucket
          region
          key
          mimeType
        }
        created
        pinned
        state
        owner
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draft {
          id
          durationInMillis
          name
          pinned
          addIntro
          addOverlayLogo
          owner
          copyOfDraftID
          userID
          shopID
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        shares {
          nextToken
          startedAt
        }
      }
      views {
        items {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVideoShares = /* GraphQL */ `
  query ListVideoShares(
    $filter: ModelVideoShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const videoByVideoShortKey = /* GraphQL */ `
  query VideoByVideoShortKey(
    $videoShortKey: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoByVideoShortKey(
      videoShortKey: $videoShortKey
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const byStateAndCreatedAt = /* GraphQL */ `
  query ByStateAndCreatedAt(
    $state: VideoShareState
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStateAndCreatedAt(
      state: $state
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const videoShareByCreatedAt = /* GraphQL */ `
  query VideoShareByCreatedAt(
    $createdAt: AWSDateTime
    $sortDirection: ModelSortDirection
    $filter: ModelVideoShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoShareByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoShares = /* GraphQL */ `
  query SyncVideoShares(
    $filter: ModelVideoShareFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoShares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoShareSMSStatus = /* GraphQL */ `
  query GetVideoShareSMSStatus($id: ID!) {
    getVideoShareSMSStatus(id: $id) {
      id
      videoShareID
      deliveryReport
      status
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listVideoShareSMSStatuss = /* GraphQL */ `
  query ListVideoShareSMSStatuss(
    $filter: ModelVideoShareSMSStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoShareSMSStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoShareID
        deliveryReport
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const videoShareSMSStatusByVideoShare = /* GraphQL */ `
  query VideoShareSMSStatusByVideoShare(
    $videoShareID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVideoShareSMSStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoShareSMSStatusByVideoShare(
      videoShareID: $videoShareID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoShareID
        deliveryReport
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoShareSMSStatuses = /* GraphQL */ `
  query SyncVideoShareSMSStatuses(
    $filter: ModelVideoShareSMSStatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoShareSMSStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoShareID
        deliveryReport
        status
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoShareView = /* GraphQL */ `
  query GetVideoShareView($id: ID!) {
    getVideoShareView(id: $id) {
      id
      videoShareID
      viewDateTime
      videoStarted
      percentageViewed
      rating
      comment
      ctas {
        count
        type
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShare {
        id
        videoID
        videoShortKey
        link
        sagsnummer
        adresse
        externalLink
        navn
        disabled
        skipDisabling
        phoneNumber
        message
        owner
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        video {
          id
          uuid
          minutes
          seconds
          durationInMillis
          draftID
          created
          pinned
          state
          owner
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        views {
          nextToken
          startedAt
        }
      }
      notifications {
        items {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listVideoShareViews = /* GraphQL */ `
  query ListVideoShareViews(
    $filter: ModelVideoShareViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoShareViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const videoShareViewByVideoShare = /* GraphQL */ `
  query VideoShareViewByVideoShare(
    $videoShareID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVideoShareViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoShareViewByVideoShare(
      videoShareID: $videoShareID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoShareViews = /* GraphQL */ `
  query SyncVideoShareViews(
    $filter: ModelVideoShareViewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoShareViews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoShareViewNotification = /* GraphQL */ `
  query GetVideoShareViewNotification($id: ID!) {
    getVideoShareViewNotification(id: $id) {
      id
      videoShareViewID
      seen
      seenDateTime
      type
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoShareView {
        id
        videoShareID
        viewDateTime
        videoStarted
        percentageViewed
        rating
        comment
        ctas {
          count
          type
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShare {
          id
          videoID
          videoShortKey
          link
          sagsnummer
          adresse
          externalLink
          navn
          disabled
          skipDisabling
          phoneNumber
          message
          owner
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        notifications {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listVideoShareViewNotifications = /* GraphQL */ `
  query ListVideoShareViewNotifications(
    $filter: ModelVideoShareViewNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoShareViewNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoShareViewNotifications = /* GraphQL */ `
  query SyncVideoShareViewNotifications(
    $filter: ModelVideoShareViewNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoShareViewNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideosAndViewsStatistics = /* GraphQL */ `
  query GetVideosAndViewsStatistics($id: ID!) {
    getVideosAndViewsStatistics(id: $id) {
      id
      videos
      views
      date
      dateSearchable
      companyID
      shopID
      userID
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideosAndViewsStatisticss = /* GraphQL */ `
  query ListVideosAndViewsStatisticss(
    $filter: ModelVideosAndViewsStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideosAndViewsStatisticss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videos
        views
        date
        dateSearchable
        companyID
        shopID
        userID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideosAndViewsStatistics = /* GraphQL */ `
  query SyncVideosAndViewsStatistics(
    $filter: ModelVideosAndViewsStatisticsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideosAndViewsStatistics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videos
        views
        date
        dateSearchable
        companyID
        shopID
        userID
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExpoPushNotification = /* GraphQL */ `
  query GetExpoPushNotification($id: ID!) {
    getExpoPushNotification(id: $id) {
      id
      to
      data
      title
      body
      sentDateTime
      owner
      expoPushNotificationTicket {
        statusCode
        body
      }
      expoPushNotificationRecipt {
        statusCode
        body
      }
      videoShareViewNotificationID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      user {
        id
        cognitoUsername
        firstName
        lastName
        jobTitle
        email
        number
        expoPushToken
        avatar {
          bucket
          region
          key
          mimeType
        }
        monthlyGoal
        locale
        deactivated
        shopID
        owner
        statisticsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          subname
          cvrNumber
          senderId
          baseUrl
          privacyPolicyUrl
          deactivated
          logoBackgroundHexColor
          headerBackgroundColor
          headerTextColor
          footerBackgroundColor
          footerTextColor
          latitude
          longitude
          segment
          companyID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        statistics {
          id
          owner
          lastVideoShared
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videos {
          nextToken
          startedAt
        }
      }
      videoShareViewNotification {
        id
        videoShareViewID
        seen
        seenDateTime
        type
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoShareView {
          id
          videoShareID
          viewDateTime
          videoStarted
          percentageViewed
          rating
          comment
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
    }
  }
`;
export const listExpoPushNotifications = /* GraphQL */ `
  query ListExpoPushNotifications(
    $filter: ModelExpoPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExpoPushNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        to
        data
        title
        body
        sentDateTime
        owner
        expoPushNotificationTicket {
          statusCode
          body
        }
        expoPushNotificationRecipt {
          statusCode
          body
        }
        videoShareViewNotificationID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoShareViewNotification {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const expoPushNotificationByUser = /* GraphQL */ `
  query ExpoPushNotificationByUser(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpoPushNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    expoPushNotificationByUser(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        to
        data
        title
        body
        sentDateTime
        owner
        expoPushNotificationTicket {
          statusCode
          body
        }
        expoPushNotificationRecipt {
          statusCode
          body
        }
        videoShareViewNotificationID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoShareViewNotification {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExpoPushNotifications = /* GraphQL */ `
  query SyncExpoPushNotifications(
    $filter: ModelExpoPushNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExpoPushNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        to
        data
        title
        body
        sentDateTime
        owner
        expoPushNotificationTicket {
          statusCode
          body
        }
        expoPushNotificationRecipt {
          statusCode
          body
        }
        videoShareViewNotificationID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        user {
          id
          cognitoUsername
          firstName
          lastName
          jobTitle
          email
          number
          expoPushToken
          monthlyGoal
          locale
          deactivated
          shopID
          owner
          statisticsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        videoShareViewNotification {
          id
          videoShareViewID
          seen
          seenDateTime
          type
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
